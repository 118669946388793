/** @jsx jsx */
import { DUIButton, DUIText } from "@alch/ui";
import useIdentifyUser from "@hooks/useIdentifyUser";
import { memo, ReactElement, useCallback, useEffect, useState } from "react";
import { jsx } from "theme-ui";
import { StringParam, useQueryParam } from "use-query-params";

import ErrorView from "../components/ErrorView";
import MasterLayout from "../components/MasterLayout";
import Section from "../components/Section";
import { fullWidthSx } from "../components/styles";
import {
  canAutoverifyEmail,
  LoginResult,
  verifyEmail,
} from "../http/endpoints";
import { Loading, useWaitForLoad } from "../util/loading";

const VerifyPage = memo(function VerifyPage(): ReactElement {
  const [token] = useQueryParam("token", StringParam);

  /**
   * Part of a mechanism to defend against email services that automatically
   * crawl all links contained in emails. See `canAutoverifyEmail()` in
   * `handlers.ts` for an explanation.
   */
  const [canAutoverifyResult, setCanAutoverifyResult] = useState(
    Loading.inProgress<boolean>(),
  );
  const [verifyResult, setVerifyResult] = useState(
    Loading.inProgress<LoginResult>(),
  );
  const waitForLoad = useWaitForLoad();
  const identifyUser = useIdentifyUser();

  const confirmEmail = useCallback(() => {
    if (!token) {
      return;
    }
    waitForLoad(verifyEmail({ token }), setVerifyResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, [token]);

  useEffect(() => {
    if (!token) {
      window.location.href = "/";
    }
    waitForLoad(canAutoverifyEmail(), setCanAutoverifyResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, []);

  useEffect(() => {
    if (!canAutoverifyResult.value) {
      return;
    }
    confirmEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, [canAutoverifyResult.value]);

  useEffect(() => {
    const redirectTo = verifyResult.value?.redirectTo;
    if (redirectTo) {
      identifyUser(() => {
        window.location.href = redirectTo;
      });
    }
  }, [identifyUser, verifyResult.value?.redirectTo]);

  const shouldDisplayManualConfirm =
    canAutoverifyResult.error || canAutoverifyResult.value === false;

  return (
    <MasterLayout title="Verify Email">
      {shouldDisplayManualConfirm && (
        <Section centered={true}>
          <DUIText size="2xl">Verify email</DUIText>
          <DUIText
            size="md"
            mt={2}
            sx={{
              maxWidth: "300px",
              color: "gray.4",
            }}
          >
            Click Verify to create your account.
          </DUIText>
          <DUIButton mt={5} sx={fullWidthSx} size="lg" onClick={confirmEmail}>
            Verify
          </DUIButton>
        </Section>
      )}
      {verifyResult.error && (
        <ErrorView
          title="Failed to complete signup"
          message={verifyResult.error.message ?? "An unknown error occurred."}
        />
      )}
    </MasterLayout>
  );
});
export default VerifyPage;
